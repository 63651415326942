<template>
  <div v-if="playButton" class="w-full h-full relative group bg-black">
    <img
      v-if="video && thumbImageUrl"
      class="w-full h-full object-cover"
      :src="thumbImageUrl"
      alt=""
      loading="lazy"
    />
    <div
      class="absolute inset-0 w-16 h-16 bg-black text-white p-2 rounded-full m-auto opacity-60 group-hover:opacity-90"
    >
      <Icon class="w-full h-full" name="MdiPlayCircle" />
    </div>
  </div>
  <div v-else>
    <img
      v-if="video && thumbImageUrl"
      class="w-full h-full object-cover"
      :src="thumbImageUrl"
      alt=""
      loading="lazy"
    />
  </div>
</template>

<script>
import urlParser from "js-video-url-parser";

export default {
  props: {
    url: { type: String, default: null },
    playButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    video() {
      return urlParser.parse(this.url);
    },

    thumbImageUrl() {
      return this[this.video?.provider];
    },

    youtube() {
      // https://stackoverflow.com/a/34032367/3165956
      return `https://img.youtube.com/vi/${this.video?.id}/hqdefault.jpg`;
    },

    vimeo() {
      // https://stackoverflow.com/questions/1361149/get-img-thumbnails-from-vimeo
      return `https://vumbnail.com/${this.video?.id}.jpg`;
    },

    dailymotion() {
      // https://stackoverflow.com/questions/13173641/how-to-get-the-video-thumbnail-from-dailymotion-video-from-the-video-id-of-that
      return `https://www.dailymotion.com/thumbnail/video/${this.video?.id}`;
    },

    // TODO:
    // Add Support For Facebook & Wistia Video Supports
  },
};
</script>
