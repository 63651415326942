<template>
  <div
    class="overflow-hidden bg-gray-100 flex justify-center items-center relative dark:bg-gray-800 dark:border dark:border-gray-800"
    :class="{
      'border rounded-md ': border,
    }"
  >
    <figure
      class="w-full"
      :class="{
        'aspect-w-1 aspect-h-1': isNull || ratio,
      }"
    >
      <!-- Image -->
      <img
        v-if="image"
        class="w-full h-full object-contain"
        :src="image"
        :alt="alt"
        loading="lazy"
      />

      <!-- Video -->
      <EmbededVideoThumb
        v-else-if="video"
        :url="video"
        :alt="alt"
        :play-button="playButton"
      />

      <!-- Fallback -->
      <div v-else class="w-1/3 h-1/3 m-auto">
        <Icon class="text-gray-300 dark:text-gray-600" name="IcTwotoneImage" />
      </div>
    </figure>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    alt: { type: String, default: null },
    size: {
      type: String,
      default: "200x200",
    },
    border: {
      type: Boolean,
      default: true,
    },
    ratio: {
      type: Boolean,
      default: false,
    },
    playButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isNull() {
      return !this?.image && !this.video;
    },

    image() {
      return this.value?.find((item) =>
        (item.mimetype || "image").startsWith("image")
      )?.size?.[`_${this.size}`];
    },
    video() {
      return this.value?.find((item) => item.mimetype?.startsWith("video"))
        ?.url;
    },
  },
};
</script>
